import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />
    <div className="usa-section">
      <div className="grid-container">
        <div className="grid-row grid-gap">
          <div className="usa-layout-docs__sidenav desktop:grid-col-3">
            <nav aria-label="Secondary navigation">
              <ul className="usa-sidenav">
                <li className="usa-sidenav__item">
                  <a href="/">Home</a>
                </li>
                <li className="usa-sidenav__item">
                  <a href="/overview" className="usa-current">
                    Overview
                  </a>
                  <ul className="usa-sidenav__sublist">
                    <li className="usa-sidenav__item">
                      <a href="#section-heading-credentials">API Credentials</a>
                    </li>
                    <li className="usa-sidenav__item">
                      <a href="#section-heading-spec">API Specification</a>
                    </li>
                  </ul>
                </li>
                <li className="usa-sidenav__item">
                  <a href="/swagger">API Sandbox</a>
                </li>
              </ul>
            </nav>
          </div>

          <main
            className="usa-layout-docs__main desktop:grid-col-9 usa-prose usa-layout-docs"
            id="main-content"
          >
            <h1>Overview</h1>

            <p className="usa-intro">
              Every capability that is available on 4Innovation web application
              is powered by APIs. Use the same APIs to securely interact with
              4Innovation platform to automate tasks, integrate with internal
              systems and manage all operations.
            </p>

            <h2 id="section-heading-credentials">API Credentials</h2>
            <p>
              To use APIs, you need to request for API Crendentials. A
              self-service page will be made available soon, that will allow you
              to directly create API Credentials.
            </p>

            <p>
              To request API credentials for working with DC model, please
              email: DPC@cms.hhs.gov and for working with KCC model, please
              email: KCF-CKCC-CMMI@cms.hhs.gov.
            </p>

            <h2 id="section-heading-spec">API Specification</h2>

            <p>
              The 4Innovation API is fully described in an OpenAPI 3.0 compliant
              document. OpenAPI is a standard specification for describing APIs.
              OpenAPI descriptions allow both humans and machines to discover
              the capabilities of an API without needing to first read
              documentation or understand the implementation.
            </p>

            <section
              class="usa-site-alert usa-site-alert--info usa-site-alert--no-icon"
              aria-label="Site alert"
            >
              <div class="usa-alert">
                <div class="usa-alert__body">
                  <h3 class="usa-alert__heading">
                    Current, active version is v1
                  </h3>
                  <p class="usa-alert__text">
                    View and download the 4Innovation API v1 specification here,
                    on
                    <Link to="/swagger">
                      <span> API Sandbox </span>
                    </Link>
                    page.
                  </p>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>
  </Layout>
)

export default SecondPage
